import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth, API } from 'aws-amplify'
import store from '../store'
import utilityJs from '../js/utility'
const utility = utilityJs.data()

// import BillingAmount from '../views/portal/BillingAmount.vue'
// import PortalIndex from '../views/portal/Index.vue'
// import PortalHome from '../views/portal/Schedule.vue'
// import Login from '../views/portal/Login.vue'
// // import SignUp from '../views/portal/SignUp.vue'
// import ForgotPassword from '../views/portal/ForgotPassword.vue'
// import TrialSignUp from '../views/portal/TrialSignUp.vue'
// import MemberList from '../views/portal/MemberList.vue'
// import Contact from '../views/portal/Contact.vue'
// import RequestList from '../views/portal/RequestList.vue'
// import TrialList from '../views/portal/TrialList.vue'
// import AccountInfo from '../views/portal/AccountInfo.vue'

// import ApproachHome from '../views/approach/Home.vue'
// import Enrollment from '../views/approach/Enrollment.vue'
// import EnrollmentHome from '../views/approach/EnrollmentHome.vue'
// import School from '../views/approach/School.vue'
// import Course from '../views/approach/Course.vue'
// import Join from '../views/approach/Join.vue'
// import Trial from '../views/approach/Trial.vue'
// import RequestCompleted from '../views/approach/RequestCompleted.vue'
// import jleagueCallBack from '../views/approach/jleagueCallBack.vue'
// import gmoBankCallBack from '../views/approach/gmoBankCallBack.vue'
// import SpecificQuotient from '../views/approach/SpecificQuotient.vue'
// import AboutUseWebsite from '../views/approach/AboutUseWebsite.vue'
// import SchoolRules from '../views/approach/SchoolRules.vue'
// import PrivacyPolicyFooter from '../views/approach/PrivacyPolicyFooter.vue'
// import Sorry from '../views/approach/Sorry.vue'

// import MasterIndex from '../views/master/Index.vue'
// import AccountCreate from '../views/master/AccountCreate.vue'
// import AccountManagement from '../views/master/AccountManagement.vue'
// import BillsManagement from '../views/master/BillsManagement.vue'
// import LessonsManagement from '../views/master/LessonsManagement.vue'
// import EnableManagement from '../views/master/EnableManagement.vue'
// import ScheduleManagement from '../views/master/ScheduleManagement.vue'

Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: '/' },
  {
    path: '/',
    name: 'approach',
    component: () => import('../views/approach/Index.vue'),
    children: [
      {
        path: '',
        name: 'approach-home',
        component: () => import('../views/approach/Home.vue'),
        meta: {
          isPublic: true,
          isCommander: 0,
          title: utility.globalSchoolType + 'TOP｜' + utility.globalTeamName
        }
      },
      {
        path: 'enrollment',
        name: 'enrollment',
        component: () => import('../views/approach/Enrollment.vue'),
        children: [
          {
            path: '',
            name: 'enrollment-home',
            component: () => import('../views/approach/EnrollmentHome.vue'),
            meta: {
              isPublic: true,
              isCommander: 0,
              title: utility.globalSchoolType + '入会体験受付｜' + utility.globalTeamName
            }
          },
          {
            path: 'school/:schoolId',
            name: 'school',
            component: () => import('../views/approach/School.vue'),
            meta: {
              isPublic: true,
              isCommander: 0
            }
          },
          {
            path: 'course/:courseId',
            name: 'course',
            component: () => import('../views/approach/Course.vue'),
            meta: {
              isPublic: true,
              isCommander: 0
            }
          },
          {
            path: 'join',
            name: 'join',
            component: () => import('../views/approach/Join.vue'),
            meta: {
              isPublic: false,
              isCommander: 0
            }
          },
          {
            path: 'trial',
            name: 'trial',
            component: () => import('../views/approach/Trial.vue'),
            meta: {
              isPublic: false,
              isCommander: 0
            }
          },
          {
            path: 'request-completed',
            name: 'request-completed',
            component: () => import('../views/approach/RequestCompleted.vue'),
            meta: {
              isPublic: false,
              isCommander: 0
            }
          }
        ]
      },
      {
        path: 'specific-quotient',
        name: 'specific-quotient',
        component: () => import('../views/approach/SpecificQuotient.vue'),
        meta: {
          isPublic: true,
          isCommander: 0,
          title: '特定商取引法に基づく表記｜' + utility.globalTeamName
        }
      },
      {
        path: 'about-use-website',
        name: 'about-use-website',
        component: () => import('../views/approach/AboutUseWebsite.vue'),
        meta: {
          isPublic: true,
          isCommander: 0,
          title: 'ウェブサイトのご利用について'
        }
      },
      {
        path: 'vegalta-privacy-policy',
        name: 'vegalta-privacy-policy',
        component: () => import('../views/approach/PrivacyPolicyFooter.vue'),
        meta: {
          isPublic: true,
          isCommander: 0,
          title: 'プライバシーポリシーについて'
        }
      },
      {
        path: 'school-rules',
        name: 'school-rules',
        component: () => import('../views/approach/SchoolRules.vue'),
        meta: {
          isPublic: true,
          isCommander: 0,
          title: utility.globalSchoolType + '規約について'
        }
      }
    ]
  },
  {
    path: '/portal',
    name: 'portal',
    component: () => import('../views/portal/Index.vue'),
    meta: {
      isPublic: false,
      isCommander: 0,
      title: utility.globalSchoolType + 'マイページ｜' + utility.globalTeamName
    },
    children: [
      {
        path: '',
        name: 'portal-home',
        component: () => import('../views/portal/Schedule.vue')
      },
      {
        path: 'billing-amount',
        name: 'billingAmount',
        component: () => import('../views/portal/BillingAmount.vue'),
        meta: {
          isCommander: 1
        }
      },
      {
        path: 'member-list',
        name: 'memberList',
        component: () => import('../views/portal/MemberList.vue'),
        meta: {
          isCommander: 1
        }
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('../views/portal/Contact.vue')
      },
      {
        path: 'request-list',
        name: 'requestList',
        component: () => import('../views/portal/RequestList.vue'),
        meta: {
          isCommander: 1
        }
      },
      {
        path: 'trial-list',
        name: 'trialList',
        component: () => import('../views/portal/TrialList.vue'),
        meta: {
          isCommander: 1
        }
      },
      {
        path: 'account-info',
        name: 'accountInfo',
        component: () => import('../views/portal/AccountInfo.vue')
      },
      {
        path: 'annual-update',
        name: 'annualUpdate',
        component: () => import('../views/portal/AnnualUpdate.vue'),
        meta: {
          isCommander: 1
        }
      },
      {
        path: 'transfer-request',
        name: 'transferRequest',
        component: () => import('../views/portal/TransferRequestList.vue'),
        meta: {
          isCommander: 1
        }
      },
      {
        path: 'event-management',
        name: 'eventManagement',
        component: () => import('../views/portal/EventManagement.vue'),
        meta: {
          isPublic: false,
          isCommander: 1,
          title: utility.globalSchoolType + 'マイページ｜' + utility.globalTeamName,
          isEvent: true
        }
      }
    ]
  },
  {
    path: '/master-maintenance',
    name: 'master',
    component: () => import('../views/master/Index.vue'),
    meta: {
      isPublic: false,
      isCommander: 2,
      title: 'マスタメンテナンスページ｜' + utility.globalTeamName
    },
    children: [
      {
        path: 'account-create',
        name: 'accountCreate',
        component: () => import('../views/master/AccountCreate.vue')
      },
      {
        path: 'account-management',
        name: 'accountManagement',
        component: () => import('../views/master/AccountManagement.vue')
      },
      {
        path: 'bills-management',
        name: 'billsManagement',
        component: () => import('../views/master/BillsManagement.vue')
      },
      {
        path: 'lessons-management',
        name: 'lessonsManagement',
        component: () => import('../views/master/LessonsManagement.vue')
      },
      {
        path: 'enable-management',
        name: 'enableManagement',
        component: () => import('../views/master/EnableManagement.vue')
      },
      {
        path: 'schedule-management',
        name: 'scheduleManagement',
        component: () => import('../views/master/ScheduleManagement.vue')
      },
      {
        path: 'transfer-management',
        name: 'transferManagement',
        component: () => import('../views/master/TransferManagement.vue')
      },
      {
        path: 'top-maintenance',
        name: 'topMaintenance',
        component: () => import('../views/master/TopMaintenance.vue')
      }
    ]
  },
  {
    path: '/event',
    name: 'eventTop',
    component: () => import('../views/event/EventTop.vue'),
    meta: {
      isPublic: true,
      isCommander: 0,
      title: 'イベント一覧｜' + utility.globalTeamName,
      isEvent: true
    }
  },
  {
    path: '/event/details',
    name: 'eventDetails',
    component: () => import('../views/event/EventDetail.vue'),
    meta: {
      isPublic: true,
      isCommander: 0,
      title: 'イベント詳細｜' + utility.globalTeamName,
      isEvent: true
    }
  },
  {
    path: '/event/login',
    name: 'eventRequestLogin',
    component: () => import('../views/event/EventRequestLogin.vue'),
    meta: {
      isPublic: true,
      isCommander: 0,
      title: 'ログイン｜' + utility.globalTeamName,
      isEvent: true
    }
  },
  {
    path: '/event/request',
    name: 'eventRequest',
    component: () => import('../views/event/EventRequest.vue'),
    meta: {
      isPublic: true,
      isCommander: 0,
      title: 'イベント申し込み｜' + utility.globalTeamName,
      isEvent: true
    }
  },
  {
    path: '/event/request-completed',
    name: 'eventRequestCompleted',
    component: () => import('../views/event/RequestCompleted.vue'),
    meta: {
      isPublic: true,
      isCommander: 0,
      title: 'イベント申し込み完了｜' + utility.globalTeamName,
      isEvent: true
    }
  },
  {
    path: '/sorry',
    name: 'sorry',
    component: () => import('../views/approach/Sorry.vue'),
    meta: {
      isPublic: true,
      isCommander: 0,
      title: 'エラー｜' + utility.globalTeamName
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/portal/Login.vue'),
    meta: {
      isPublic: true,
      isCommander: 0,
      title: 'ログイン｜' + utility.globalTeamName
    }
  },
  {
    path: '/signup',
    name: 'signUp',
    component: () => import('../views/portal/SignUp.vue'),
    meta: {
      isPublic: true,
      isCommander: 0
    }
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('../views/portal/ForgotPassword.vue'),
    meta: {
      isPublic: true,
      isCommander: 0,
      title: 'パスワードを忘れた方｜' + utility.globalTeamName
    }
  },
  {
    path: '/trial-signup',
    name: 'trialSignUp',
    component: () => import('../views/portal/TrialSignUp.vue'),
    meta: {
      isPublic: true,
      isCommander: 0,
      title: 'メールアドレス認証｜' + utility.globalTeamName
    }
  },
  {
    path: '/oauth2/idpresponse',
    name: 'jleagueCallBack',
    component: () => import('../views/approach/jleagueCallBack.vue'),
    meta: {
      isPublic: true,
      isCommander: 0,
      title: '自動的に遷移します。｜' + utility.globalTeamName
    }
  },
  {
    path: '/gmo/bank-register-callback',
    name: 'gmoBankCallBack',
    component: () => import('../views/approach/gmoBankCallBack.vue'),
    meta: {
      isPublic: true,
      isCommander: 0,
      title: '自動的に遷移します。｜' + utility.globalTeamName
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async function (to, from, next) {
  store.commit('initLoading')
  const myPromise = new Promise((resolve, reject) => {
    let maintenanceMode = false
    store.commit('startLoading')
    store.commit('setFromPageInfo', {
      name: from.name,
      path: from.path,
      fullPath: from.fullPath
    })
    if (process.env.VUE_APP_MAINTENANCE === 'true' && to.name !== 'sorry') {
      reject(new Error('system is maintenance mode'))
    }
    API.get('integrationNotAuth', '/util/get_maintenance_mode', { response: true }).then(result => {
      maintenanceMode = result.data.isMaintenance
      if (maintenanceMode && to.name !== 'sorry') {
        reject(new Error('system is maintenance mode'))
      }
    })
    if (to.matched.some(page => page.meta.isEvent) && !store.state.eventUseFlag) {
      next('/')
      document.title = utility.globalSchoolType + 'TOP｜' + utility.globalTeamName
    }
    Auth.currentAuthenticatedUser().then(async function (response) {
      if (response && response.username.split('@:')[0] === 'trial' && to.name !== 'trial' && to.name !== 'request-completed' && to.name !== 'enrollment-home' && to.name !== 'course' && to.name !== 'school' && to.name !== 'trialSignUp') {
        Auth.signOut()
        store.commit('setMyCourse', [])
        store.commit('setLogined', false)
        store.commit('setLogout')
        next('/')
        document.title = utility.globalSchoolType + 'TOP｜' + utility.globalTeamName
        store.commit('finishLoading')
      } else {
        const apiName = 'integration'
        const path = '/registers/statuses'
        const mail = response.attributes ? response.attributes.email : response.signInUserSession.idToken.payload.email
        const jid = response.signInUserSession.idToken.payload['custom:JleagueID'] ? response.signInUserSession.idToken.payload['custom:JleagueID'] : null
        if (to.name !== 'trial' && to.name !== 'request-completed' && !to.meta.isPublic && to.name !== null) {
          if (store.state.accountType === 0) {
            await API.post(apiName, path, { body: { email: mail, jid: jid } }).then(result => {
              store.commit('setUserStatus', result)
            }, e => {
              console.log(1)
              store.commit('setInitUserStatus')
            }).catch(e => {
              console.log(2)
              store.commit('setInitUserStatus')
            })
          }

          if (!store.state.loginId && !store.state.loginName) {
            store.commit('setLoginData', {
              id: response.username,
              userId: '',
              name: '',
              mail: mail,
              jid: jid
            })
          }
        }
        if (to.matched.some(page => page.meta.isCommander)) {
          const permissionGroup = to.matched.some(page => page.meta.isCommander) + 2
          if (store.state.accountType >= permissionGroup) {
            next()
            document.title = to.matched.find(v => v.meta.title) ? to.matched.find(v => v.meta.title).meta.title : utility.globalSchoolType + 'TOP｜' + utility.globalTeamName
          } else {
            next('/')
            document.title = utility.globalSchoolType + 'TOP｜' + utility.globalTeamName
          }
        } else {
          next()
          document.title = to.matched.find(v => v.meta.title) ? to.matched.find(v => v.meta.title).meta.title : utility.globalSchoolType + 'TOP｜' + utility.globalTeamName
        }
        store.commit('finishLoading')
      }
    }).catch(e => {
      console.log(e)
      if (to.matched.some(page => page.meta.isPublic)) {
        next()
        document.title = to.matched.find(v => v.meta.title) ? to.matched.find(v => v.meta.title).meta.title : utility.globalSchoolType + 'TOP｜' + utility.globalTeamName
      } else {
        next('/login?to=' + to.fullPath)
        document.title = 'ログイン｜' + utility.globalTeamName
      }
      store.commit('finishLoading')
    })
  })
  myPromise.catch(() => {
    next('/sorry')
    store.commit('initLoading')
  })
})

export default router
