import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: { // A
          base: '#008000',
          darken1: '#36c236', // R
          lighten1: '#D8F2D8' // C
        },
        primaryBtnText: { // A'
          base: '#FFFFFF'
        },
        secondary: { // B
          base: '#000000',
          darken1: '#015001' // D
        },
        secondaryBtnText: { // B'
          base: '#FFFFFF'
        },
        headerBack: { // E
          base: '#008000'
        },
        headerText: { // F
          base: '#FFFFFF'
        },
        logoutBtnText: { // G
          base: '#FFFFFF'
        },
        topSchoolNamePc: { // H
          base: '#008000'
        },
        topSecondaryBtn: { // I
          base: '#FFFFFF'
        },
        topSecondaryBtnText: { // J
          base: '#000000'
        },
        topPrimaryBtn: { // K
          base: '#008000'
        },
        topPrimaryBtnText: { // L
          base: '#FFFFFF'
        },
        topBtnUpText: { // M
          base: '#FFFFFF'
        },
        primaryOutLineBtnText: { // N
          base: '#008000'
        },
        secondaryOutLineBtnText: { // O
          base: '#008000'
        },
        topBtnUpTextSp: { // P
          base: '#008000'
        },
        topBtnBackSp: { // Q
          base: '#FFFFFF'
        },
        eventHeaderBack: {
          base: '#008000'
        },
        eventHeaderText: {
          base: '#FFFFFF'
        },
        eventTopBorderSub: {
          base: '#008000'
        },
        accent: '#008000', // datepickerなどの色
        formBackground: '#F6f6f6'
      },
      dark: {
        primary: '#008000',
        secondary: '#43A047',
        accent: '#162988',
        formBackground: '#F6f6f6'
      }
    }
  }
})
